<template>
    <main role="main">

        <!-- Main jumbotron for a primary marketing message or call to action -->
        <div class="jumbotron">
            <div class="container">
                <div class="row">
                    <table class="table">
                        <thead>
                            <tr>
                                <th scope="col" style="width:120px;">項目</th>
                                <th scope="col">說明</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th scope="row">訪客人次</th>
                                <td>代表『日期範圍區間』的不重複訪問人次，個人使用者參訪到您的網站，若相同的使用者離開您的網站稍後又返回，系統會認得該名使用者，並且不會重複計算</td>
                            </tr>
                            <tr>
                                <th scope="row">進站次數<br>(工作階段)</th>
                                <td>它能真實反應訪客使用網站的狀況，系統預設工作階段逾時時間為30分鐘，也就是30分鐘內，訪客所有的網頁行為、互動皆會被計算為一個工作階段；相反來說，若訪客超過30分鐘並沒有網頁互動行為，訪客當前工作階段將會結束，之後若訪客又跟網頁產生新的互動，則會以另一個新的工作階段計算
                                </td>
                            </tr>
                            <tr>
                                <th scope="row">網頁瀏覽量</th>
                                <td>只要使用者瀏覽網頁一次，瀏覽量(Pageview)就會算一次，不管是重新整理、關掉頁籤再回來、點連結到別頁再回來。因為讀取該頁時候，就會觸發 Google
                                    Analytics 追蹤碼，送出網頁追蹤匹配。</td>
                            </tr>
                            <tr>
                                <th scope="row">跳出率</th>
                                <td>當訪客進入到網站後，沒有到其他頁面進一步瀏覽就離站，即算跳出。而跳出率就是『僅瀏覽一頁的訪客』的比例</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

            </div>
        </div>
        <!-- <ve-line :data="chartData"></ve-line> -->
        <div id="ga-report" class="container">
            <div class="form-row mb-5">
                <div class="col-md-4">
                    <div class="input-group mb-3 date">
                        <div class="input-group-prepend"><span id="inputGroup-sizing-default"
                                class="input-group-text">指定月份</span></div>
                        <vue-monthly-picker v-model="selectedMonth">
                        </vue-monthly-picker>
                    </div>
                </div>
                <div class="col-md-6"><label for="staticEmail" class="col-form-label ml-2 mr-2">依百分比顯示</label>
                    <b-button v-if="percentageBtn" variant="primary" @click="percentageBtn = !percentageBtn">開啟
                    </b-button>
                    <b-button v-else @click="percentageBtn = !percentageBtn">關閉</b-button>
                    <button type="button" class="btn btn-primary ml-3" @click="getReport()" v-show="!loading">查詢
                    </button>
                    <b-button class="ml-3" variant="primary" disabled v-show="loading">
                        <b-spinner small type="grow"></b-spinner>
                        Loading...
                    </b-button>
                </div>
            </div>
            <div class="row" v-show="getDataState">
                <div class="pricing-header px-3 py-3 pb-md-4 mx-auto text-center">
                    <h1 class="display-4">{{ titleDate }}月</h1>
                    <p class="lead">各累計資料皆包含初次造訪及回訪者與網站的互動</p>
                </div>
            </div>
            <div class="container" v-show="getDataState">
                <apexchart height="300" type="line" :options="chartOptions" :series="series"></apexchart>
            </div>
            <div class="mb-5" style="height: 350px; display: none;"><canvas id="myChart"></canvas></div>
            <div class="mb-5" v-show="getDataState">
                <div class="card-deck text-center" style="">
                    <div class="card mb-4 box-shadow">
                        <div class="card-header">
                            <h4 class="my-0 font-weight-normal"><a href="javascript:void(0);"
                                    @click="setChart('users')">訪客人次</a></h4>
                        </div>
                        <div class="card-body">
                            <h1 class="card-title pricing-card-title">{{ users }}</h1>
                        </div>
                    </div>
                    <div class="card mb-4 box-shadow">
                        <div class="card-header">
                            <h4 class="my-0 font-weight-normal"><a href="javascript:void(0);"
                                    @click="setChart('sessions')">進站次數</a></h4>
                        </div>
                        <div class="card-body">
                            <h1 class="card-title pricing-card-title">{{ sessions }}</h1>
                        </div>
                    </div>
                    <div class="card mb-4 box-shadow">
                        <div class="card-header">
                            <h4 class="my-0 font-weight-normal"><a href="javascript:void(0);"
                                    @click="setChart('pageviews')">網頁瀏覽量</a></h4>
                        </div>
                        <div class="card-body">
                            <h1 class="card-title pricing-card-title">{{ pageviews }}</h1>
                        </div>
                    </div>
                </div>
                <div class="card-deck text-center" style="">
                    <div class="card mb-4 box-shadow">
                        <div class="card-header">
                            <h4 class="my-0 font-weight-normal">跳出率</h4>
                        </div>
                        <div class="card-body">
                            <h1 class="card-title pricing-card-title">{{ bounceRate }}</h1>
                        </div>
                    </div>
                    <div class="card mb-4 box-shadow">
                        <div class="card-header">
                            <h4 class="my-0 font-weight-normal">平均進站瀏覽頁數</h4>
                        </div>
                        <div class="card-body">
                            <h1 class="card-title pricing-card-title">{{ pageviewsPerSession }}</h1>
                        </div>
                    </div>
                    <div class="card mb-4 box-shadow">
                        <div class="card-header">
                            <h4 class="my-0 font-weight-normal">平均進站停留時間</h4>
                        </div>
                        <div class="card-body">
                            <h1 class="card-title pricing-card-title">{{ avgSessionDuration }}</h1>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="container mb-5" v-for="(item, index) in tableData" :key="index" v-show="getDataState">
            <div class="row">
                <div class="col-lg-12 col-sm-12 col-12 report-header">
                    <div class="row">
                        <div class="col-lg-1 col-sm-1 col-3"><img :src="tableHeader[index].imgUrl" width="120px"
                                class="img-thumbnail"></div>
                        <div class="col-lg-9 col-sm-11 col-9">
                            <h4 class="text-primary">{{ tableHeader[index].title }}</h4>
                            <p>
                                {{ tableHeader[index].content }}
                            </p>
                        </div>
                        <div class="col-lg-1 col-sm-1 col-3 text-right"><button id="button"
                                class="btn btn-secondary">展開</button></div>
                    </div>
                </div>
            </div>
            <BootstrapTable :columns="Columns" :data="tableData[index]" :options="options" />
        </div>


    </main>
</template>

<script>
    import BootstrapTable from 'bootstrap-table/dist/bootstrap-table-vue.esm.js'
    import VueMonthlyPicker from 'vue-monthly-picker'
    import apexchart from 'vue-apexcharts'
    export default {
        name: 'AlbumBody',
        props: {
            title: String
        },
        components: {
            BootstrapTable,
            VueMonthlyPicker,
            apexchart
        },
        data() {
            return {
                loading: false,
                series: [{
                    name: "High - 2013",
                    data: [28, 29, 33, 36, 32, 32, 33]
                }, ],
                chartOptions: {
                    chart: {
                        height: 350,
                        type: 'line',
                        dropShadow: {
                            enabled: true,
                            color: '#000',
                            top: 18,
                            left: 7,
                            blur: 10,
                            opacity: 0.2
                        },
                        toolbar: {
                            show: false
                        }
                    },
                    colors: ['#77B6EA', '#545454'],
                    dataLabels: {
                        enabled: true,
                    },
                    stroke: {
                        curve: 'smooth'
                    },
                    title: {
                        text: 'Average High & Low Temperature',
                        align: 'left'
                    },
                    grid: {
                        borderColor: '#e7e7e7',
                        row: {
                            colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                            opacity: 0.5
                        },
                    },
                    markers: {
                        size: 1
                    },
                    xaxis: {
                        categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul'],
                        title: {
                            text: 'Month'
                        }
                    },
                    yaxis: {
                        title: {
                            text: 'Temperature'
                        }
                    },
                    legend: {
                        position: 'top',
                        horizontalAlign: 'right',
                        floating: true,
                        offsetY: -25,
                        offsetX: -5
                    }
                },
                selectedMonth: "",
                percentageBtn: false,
                myDataVariable: false,
                apiUrl: `${process.env.VUE_APP_API_URL}`,
                getDataState: false,
                date: '',
                avgSessionDuration: "",
                bounceRate: "",
                pageviews: '',
                pageviewsPerSession: '',
                sessions: '',
                users: '',
                Columns: [{
                        field: 'category',
                        title: '#'
                    },
                    {
                        field: 'users',
                        title: '訪客人次'
                    },
                    {
                        field: 'sessions',
                        title: '進站次數'
                    },
                    {
                        field: 'pageviews',
                        title: '網頁瀏覽量'
                    },
                    {
                        field: 'bounceRate',
                        title: '跳出率'
                    },
                    {
                        field: 'pageviewsPerSession',
                        title: '單次進站瀏覽頁數'
                    },
                    {
                        field: 'avgSessionDuration',
                        title: '單次進站停留時間'
                    }

                ],
                tableData: [],
                chartData: [],
                options: {
                    paginationHAlign: 'left',
                    pageSize: 8,
                    pagination: true,
                    pageInfo: false,
                    paginationParts: ['pageList']
                },
                tableHeader: {
                    "language": {
                        "title": "訪客使用語系",
                        "content": "本表為各語系之使用者對網站的使用量",
                        "imgUrl": "http://ts.cteam.com.tw:86/project/stuccd/report/img/icon_language.png"
                    },
                    "country": {
                        "title": "訪客分布的國家",
                        "content": "本表為各國家對網站的使用量",
                        "imgUrl": "http://ts.cteam.com.tw:86/project/stuccd/report/img/icon_country.png"
                    },
                    "deviceCategory": {
                        "title": "訪客使用裝置",
                        "content": "本表為訪客瀏覽網站所使用的裝置媒介",
                        "imgUrl": "http://ts.cteam.com.tw:86/project/stuccd/report/img/icon_deviceCategory.png"
                    },
                    "pagePath": {
                        "title": "訪客瀏覽網頁情形",
                        "content": "本表為訪客瀏覽網站較傾向接觸的資訊",
                        "imgUrl": "http://ts.cteam.com.tw:86/project/stuccd/report/img/icon_pagePath.png"
                    },
                    "sourceMedium": {
                        "title": "訪客流量來源",
                        "content": "本表為訪客進入網站的來源平台",
                        "imgUrl": "http://ts.cteam.com.tw:86/project/stuccd/report/img/icon_sourceMedium.png"
                    },
                    "date": {
                        "title": "時間資料總覽",
                        "content": "時間資料總覽情況",
                        "imgUrl": "http://ts.cteam.com.tw:86/project/stuccd/report/img/icon_date.png"
                    }
                },
                report: {
                    "language": {
                        "tableId": "categoryTable",
                        "title": "訪客使用語系",
                        "description": "本表為各語系之使用者對網站的使用量",
                        "translation": {
                            "de-de": "德國-德語",
                            "en": "英語",
                            "en-gb": "英語-大英國協",
                            "en-in": "英語-印度",
                            "en-my": "英語-馬來西亞",
                            "en-ph": "英語-菲律賓",
                            "en-us": "英語-美國",
                            "en-ca ": "英語-加拿大",
                            "fi-fi": "芬蘭語-芬蘭",
                            "nl": "荷蘭語",
                            "fr": "法語",
                            "ja": "日語",
                            "ja-jp": "日語-日本",
                            "ko": "韓文",
                            "ko-kr": "韓文-韓國",
                            "zh-cn": "簡體中文-中國",
                            "zh-tw": "繁體中文-台灣",
                            "zh-hk": "中文-香港",
                            "zh": "中文",
                            "es-es": "西班牙語",
                            "vi-vn": "越南語",
                            "id-id": "印尼",
                            "en-au": "英語-澳洲"
                        }
                    },
                    "country": {
                        "tableId": "countryTable",
                        "title": "訪客分布的國家",
                        "description": "本表為各國家對網站的使用量",
                        "translation": {
                            "Australia": "澳洲",
                            "China": "中國",
                            "Finland": "芬蘭",
                            "France": "法國",
                            "Germany": "德國",
                            "India": "印度",
                            "Indonesia": "印尼",
                            "Japan": "日本",
                            "Macao": "澳門",
                            "Malaysia": "馬來西亞",
                            "Netherlands": "荷蘭",
                            "Philippines": "菲律賓",
                            "Singapore": "新加坡",
                            "South Korea": "南韓",
                            "Taiwan": "台灣",
                            "Thailand": "泰國",
                            "Hong Kong": "香港",
                            "Pakistan": "巴基斯坦",
                            "Nigeria": "奈及利亞",
                            "United States": "美國",
                            "Canada": "加拿大",
                            "United Kingdom": "英國",
                            "United Arab Emirates": "阿拉伯聯合大公國",
                            "Italy": "義大利",
                            "Argentina": "阿根廷",
                            "Belgium": "比利時",
                            "Ireland": "愛爾蘭",
                            "Myanmar (Burma)": "緬甸",
                            "New Zealand": "紐西蘭",
                            "Spain": "西班牙",
                            "Vietnam": "越南"
                        }
                    },
                    "deviceCategory": {
                        "tableId": "deviceCategoryTable",
                        "title": "訪客使用裝置",
                        "description": "本表為訪客瀏覽網站所使用的裝置媒介",
                        "translation": {
                            "desktop": "桌上型電腦",
                            "mobile": "手機",
                            "tablet": "平板"
                        }
                    },
                    "pagePath": {
                        "tableId": "pagePathTable",
                        "title": "訪客瀏覽網頁情形",
                        "description": "本表為訪客瀏覽網站較傾向接觸的資訊",
                        "translation": {
                            "/": "首頁"
                        }
                    },
                    "sourceMedium": {
                        "tableId": "sourceMediumTable",
                        "title": "訪客流量來源",
                        "description": "本表為訪客進入網站的來源平台",
                        "translation": {
                            "google / organic": "Google",
                            "(direct) / (none)": "官方連結"
                        }
                    },
                    "date": {
                        "tableId": "dateTable",
                        "title": "時間資料總覽",
                        "description": "時間資料總覽情況",
                        "translation": {}
                    }

                },
                titleDate: ''
            }
        },
        mounted() {

        },
        methods: {
            getReport() {
                console.log(this.selectedMonth);
                if (this.selectedMonth == '') {
                    this.$swal({
                        icon: 'error',
                        title: '請選擇日期！',
                        text: '請選擇日期，才能做出查詢的動作。',
                    });
                    return;
                }
                this.loading = true;
                const getDate = new Date(this.selectedMonth);
                const selectYear = getDate.getFullYear();
                const selectedMonth = getDate.getMonth() + 1;
                this.titleDate = selectYear + "-" + selectedMonth;
                var selectedDay = new Date(selectYear, selectedMonth, 0).getDate();
                selectedDay = selectedDay < 10 ? '0' + selectedDay : selectedDay;

                const api =
                    `http://ts.cteam.com.tw:86/api_service/features/google/analysis?start_time=${selectYear}-${selectedMonth}-01&end_time=${selectYear}-${selectedMonth}-${selectedDay}`
                this.axios.get(api).then((response) => {

                    //6個card
                    let data = response["data"]
                    let total = data['all']['total'];
                    this.avgSessionDuration = this.formatSecond(total['avgSessionDuration'])
                    this.bounceRate = this.valFormat(total['bounceRate'])
                    this.pageviews = total['pageviews']
                    this.pageviewsPerSession = total['pageviewsPerSession'].toFixed(2)
                    this.sessions = total['sessions']
                    this.users = total['users']

                    let msg = data;

                    //圖表的部分
                    this.getLineData(msg);
                    this.setChart('users', this.chartData);

                    let asPct = this.percentageBtn;//百分比狀態

                    //資料整理部分
                    let vdata = {};
                    for (let key in this.report) {
                        let dataset = msg[key]['dataset'];
                        vdata[key] = [];
                        for (let index in dataset) {
                            let lan = dataset[index].category[0];
                            if (Object.prototype.hasOwnProperty.call(this.report[key].translation, lan)) {
                                dataset[index].category = this.report[key].translation[lan];
                            }
                            this.valueFormat(dataset[index].value);
                            vdata[key].push({
                                'category': dataset[index].category,
                                'users': (asPct) ? this.goRound((dataset[index].value.users / msg[key]
                                    .total
                                    .users) * 100) + "%" : dataset[index].value.users,
                                'sessions': (asPct) ? this.goRound((dataset[index].value.sessions / msg[
                                        key]
                                    .total.sessions) * 100) + "%" : dataset[index].value.sessions,
                                'pageviews': (asPct) ? this.goRound((dataset[index].value.pageviews /
                                        msg[
                                            key].total.pageviews) * 100) + "%" : dataset[index].value
                                    .pageviews,
                                'bounceRate': dataset[index].value.bounceRate,
                                'pageviewsPerSession': dataset[index].value.pageviewsPerSession,
                                'avgSessionDuration': dataset[index].value.avgSessionDuration
                            });
                        }

                    }
                    this.tableData = vdata;
                    //開啟所有表格、loading動畫
                    this.getDataState = true;
                    this.loading = false;
                })
            },
            //時間補0
            padLeft(str, len) {
                str = '' + str;
                if (str.length >= len) {
                    return str;
                } else {
                    return this.padLeft("0" + str, len);
                }
            },
            //設定時間格式
            formatSecond(secs) {
                var hr = Math.floor(secs / 3600);
                var min = Math.floor((secs - (hr * 3600)) / 60);
                var sec = parseInt(secs - (hr * 3600) - (min * 60));
                return this.padLeft(hr, 2) + ":" + this.padLeft(min, 2) + ':' + this.padLeft(sec, 2);
            },
            //加上百分比
            valFormat(values) {
                values = this.goRound(values) + "%";
                return values;
            },
            //小數點
            goRound(val) {
                return (Math.round(val * 100) / 100);
            },
            //格式設定
            valueFormat(values) {
                values.bounceRate = this.goRound(values.bounceRate) + "%";
                values.pageviewsPerSession = this.goRound(values.pageviewsPerSession);
                values.avgSessionDuration = this.formatSecond(values.avgSessionDuration);
                return values;
            },
            //共用的圖表方法
            setChart(type) {
                var chartOptionDefault = {
                    'users': {
                        'label': "訪客人次",
                        'xAxes': 'Day',
                        'yAxes': 'Times',
                        'description': ''
                    },
                    'sessions': {
                        'label': "進站次數",
                        'xAxes': 'Day',
                        'yAxes': 'Times',
                        'description': ''
                    },
                    'pageviews': {
                        'label': "網頁瀏覽量",
                        'xAxes': 'Day',
                        'yAxes': 'Views',
                        'description': ''
                    }
                }

                //設定標題 x,y title
                this.chartOptions = {
                    title: {
                        text: chartOptionDefault[type].label,
                        align: 'center',
                        style: {
                            fontSize: '28px',
                            fontWeight: 'bold',
                            fontFamily: undefined,
                            color: '#263238'
                        },
                    },
                    xaxis: {
                        title: {
                            text: chartOptionDefault[type].xAxes
                        },
                        categories: this.chartData[type].labels
                    },
                    yaxis: {
                        title: {
                            text: chartOptionDefault[type].yAxes
                        },
                    }
                }
                this.series = [{
                    name: chartOptionDefault[type].label,
                    data: this.chartData[type].data
                }]
            },
            //圖標資料整理
            getLineData(msg) {
                var dateReport = {
                    'users': {
                        labels: [],
                        data: []
                    },
                    'sessions': {
                        labels: [],
                        data: []
                    },
                    'pageviews': {
                        labels: [],
                        data: []
                    }
                };
                for (let key in msg.date.dataset) {
                    for (let dateKey in dateReport) {
                        dateReport[dateKey].labels.push(msg.date.dataset[key].category[0].substr(6, 2));
                        dateReport[dateKey].data.push(msg.date.dataset[key].value[dateKey]);
                    }
                }
                this.chartData = dateReport;
            }
        }
    }
</script>

<style scoped>
    a {
        color: #007bff;
        text-decoration: none;
        background-color: transparent;
        -webkit-text-decoration-skip: objects;
    }
</style>