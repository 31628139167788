var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "main",
    { attrs: { role: "main" } },
    [
      _vm._m(0),
      _c("div", { staticClass: "container", attrs: { id: "ga-report" } }, [
        _c("div", { staticClass: "form-row mb-5" }, [
          _c("div", { staticClass: "col-md-4" }, [
            _c(
              "div",
              { staticClass: "input-group mb-3 date" },
              [
                _vm._m(1),
                _c("vue-monthly-picker", {
                  model: {
                    value: _vm.selectedMonth,
                    callback: function($$v) {
                      _vm.selectedMonth = $$v
                    },
                    expression: "selectedMonth"
                  }
                })
              ],
              1
            )
          ]),
          _c(
            "div",
            { staticClass: "col-md-6" },
            [
              _c(
                "label",
                {
                  staticClass: "col-form-label ml-2 mr-2",
                  attrs: { for: "staticEmail" }
                },
                [_vm._v("依百分比顯示")]
              ),
              _vm.percentageBtn
                ? _c(
                    "b-button",
                    {
                      attrs: { variant: "primary" },
                      on: {
                        click: function($event) {
                          _vm.percentageBtn = !_vm.percentageBtn
                        }
                      }
                    },
                    [_vm._v("開啟 ")]
                  )
                : _c(
                    "b-button",
                    {
                      on: {
                        click: function($event) {
                          _vm.percentageBtn = !_vm.percentageBtn
                        }
                      }
                    },
                    [_vm._v("關閉")]
                  ),
              _c(
                "button",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.loading,
                      expression: "!loading"
                    }
                  ],
                  staticClass: "btn btn-primary ml-3",
                  attrs: { type: "button" },
                  on: {
                    click: function($event) {
                      return _vm.getReport()
                    }
                  }
                },
                [_vm._v("查詢 ")]
              ),
              _c(
                "b-button",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.loading,
                      expression: "loading"
                    }
                  ],
                  staticClass: "ml-3",
                  attrs: { variant: "primary", disabled: "" }
                },
                [
                  _c("b-spinner", { attrs: { small: "", type: "grow" } }),
                  _vm._v(" Loading... ")
                ],
                1
              )
            ],
            1
          )
        ]),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.getDataState,
                expression: "getDataState"
              }
            ],
            staticClass: "row"
          },
          [
            _c(
              "div",
              {
                staticClass:
                  "pricing-header px-3 py-3 pb-md-4 mx-auto text-center"
              },
              [
                _c("h1", { staticClass: "display-4" }, [
                  _vm._v(_vm._s(_vm.titleDate) + "月")
                ]),
                _c("p", { staticClass: "lead" }, [
                  _vm._v("各累計資料皆包含初次造訪及回訪者與網站的互動")
                ])
              ]
            )
          ]
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.getDataState,
                expression: "getDataState"
              }
            ],
            staticClass: "container"
          },
          [
            _c("apexchart", {
              attrs: {
                height: "300",
                type: "line",
                options: _vm.chartOptions,
                series: _vm.series
              }
            })
          ],
          1
        ),
        _vm._m(2),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.getDataState,
                expression: "getDataState"
              }
            ],
            staticClass: "mb-5"
          },
          [
            _c("div", { staticClass: "card-deck text-center" }, [
              _c("div", { staticClass: "card mb-4 box-shadow" }, [
                _c("div", { staticClass: "card-header" }, [
                  _c("h4", { staticClass: "my-0 font-weight-normal" }, [
                    _c(
                      "a",
                      {
                        attrs: { href: "javascript:void(0);" },
                        on: {
                          click: function($event) {
                            return _vm.setChart("users")
                          }
                        }
                      },
                      [_vm._v("訪客人次")]
                    )
                  ])
                ]),
                _c("div", { staticClass: "card-body" }, [
                  _c("h1", { staticClass: "card-title pricing-card-title" }, [
                    _vm._v(_vm._s(_vm.users))
                  ])
                ])
              ]),
              _c("div", { staticClass: "card mb-4 box-shadow" }, [
                _c("div", { staticClass: "card-header" }, [
                  _c("h4", { staticClass: "my-0 font-weight-normal" }, [
                    _c(
                      "a",
                      {
                        attrs: { href: "javascript:void(0);" },
                        on: {
                          click: function($event) {
                            return _vm.setChart("sessions")
                          }
                        }
                      },
                      [_vm._v("進站次數")]
                    )
                  ])
                ]),
                _c("div", { staticClass: "card-body" }, [
                  _c("h1", { staticClass: "card-title pricing-card-title" }, [
                    _vm._v(_vm._s(_vm.sessions))
                  ])
                ])
              ]),
              _c("div", { staticClass: "card mb-4 box-shadow" }, [
                _c("div", { staticClass: "card-header" }, [
                  _c("h4", { staticClass: "my-0 font-weight-normal" }, [
                    _c(
                      "a",
                      {
                        attrs: { href: "javascript:void(0);" },
                        on: {
                          click: function($event) {
                            return _vm.setChart("pageviews")
                          }
                        }
                      },
                      [_vm._v("網頁瀏覽量")]
                    )
                  ])
                ]),
                _c("div", { staticClass: "card-body" }, [
                  _c("h1", { staticClass: "card-title pricing-card-title" }, [
                    _vm._v(_vm._s(_vm.pageviews))
                  ])
                ])
              ])
            ]),
            _c("div", { staticClass: "card-deck text-center" }, [
              _c("div", { staticClass: "card mb-4 box-shadow" }, [
                _vm._m(3),
                _c("div", { staticClass: "card-body" }, [
                  _c("h1", { staticClass: "card-title pricing-card-title" }, [
                    _vm._v(_vm._s(_vm.bounceRate))
                  ])
                ])
              ]),
              _c("div", { staticClass: "card mb-4 box-shadow" }, [
                _vm._m(4),
                _c("div", { staticClass: "card-body" }, [
                  _c("h1", { staticClass: "card-title pricing-card-title" }, [
                    _vm._v(_vm._s(_vm.pageviewsPerSession))
                  ])
                ])
              ]),
              _c("div", { staticClass: "card mb-4 box-shadow" }, [
                _vm._m(5),
                _c("div", { staticClass: "card-body" }, [
                  _c("h1", { staticClass: "card-title pricing-card-title" }, [
                    _vm._v(_vm._s(_vm.avgSessionDuration))
                  ])
                ])
              ])
            ])
          ]
        )
      ]),
      _vm._l(_vm.tableData, function(item, index) {
        return _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.getDataState,
                expression: "getDataState"
              }
            ],
            key: index,
            staticClass: "container mb-5"
          },
          [
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "col-lg-12 col-sm-12 col-12 report-header" },
                [
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-lg-1 col-sm-1 col-3" }, [
                      _c("img", {
                        staticClass: "img-thumbnail",
                        attrs: {
                          src: _vm.tableHeader[index].imgUrl,
                          width: "120px"
                        }
                      })
                    ]),
                    _c("div", { staticClass: "col-lg-9 col-sm-11 col-9" }, [
                      _c("h4", { staticClass: "text-primary" }, [
                        _vm._v(_vm._s(_vm.tableHeader[index].title))
                      ]),
                      _c("p", [
                        _vm._v(
                          " " + _vm._s(_vm.tableHeader[index].content) + " "
                        )
                      ])
                    ]),
                    _vm._m(6, true)
                  ])
                ]
              )
            ]),
            _c("BootstrapTable", {
              attrs: {
                columns: _vm.Columns,
                data: _vm.tableData[index],
                options: _vm.options
              }
            })
          ],
          1
        )
      })
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "jumbotron" }, [
      _c("div", { staticClass: "container" }, [
        _c("div", { staticClass: "row" }, [
          _c("table", { staticClass: "table" }, [
            _c("thead", [
              _c("tr", [
                _c(
                  "th",
                  { staticStyle: { width: "120px" }, attrs: { scope: "col" } },
                  [_vm._v("項目")]
                ),
                _c("th", { attrs: { scope: "col" } }, [_vm._v("說明")])
              ])
            ]),
            _c("tbody", [
              _c("tr", [
                _c("th", { attrs: { scope: "row" } }, [_vm._v("訪客人次")]),
                _c("td", [
                  _vm._v(
                    "代表『日期範圍區間』的不重複訪問人次，個人使用者參訪到您的網站，若相同的使用者離開您的網站稍後又返回，系統會認得該名使用者，並且不會重複計算"
                  )
                ])
              ]),
              _c("tr", [
                _c("th", { attrs: { scope: "row" } }, [
                  _vm._v("進站次數"),
                  _c("br"),
                  _vm._v("(工作階段)")
                ]),
                _c("td", [
                  _vm._v(
                    "它能真實反應訪客使用網站的狀況，系統預設工作階段逾時時間為30分鐘，也就是30分鐘內，訪客所有的網頁行為、互動皆會被計算為一個工作階段；相反來說，若訪客超過30分鐘並沒有網頁互動行為，訪客當前工作階段將會結束，之後若訪客又跟網頁產生新的互動，則會以另一個新的工作階段計算 "
                  )
                ])
              ]),
              _c("tr", [
                _c("th", { attrs: { scope: "row" } }, [_vm._v("網頁瀏覽量")]),
                _c("td", [
                  _vm._v(
                    "只要使用者瀏覽網頁一次，瀏覽量(Pageview)就會算一次，不管是重新整理、關掉頁籤再回來、點連結到別頁再回來。因為讀取該頁時候，就會觸發 Google Analytics 追蹤碼，送出網頁追蹤匹配。"
                  )
                ])
              ]),
              _c("tr", [
                _c("th", { attrs: { scope: "row" } }, [_vm._v("跳出率")]),
                _c("td", [
                  _vm._v(
                    "當訪客進入到網站後，沒有到其他頁面進一步瀏覽就離站，即算跳出。而跳出率就是『僅瀏覽一頁的訪客』的比例"
                  )
                ])
              ])
            ])
          ])
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "input-group-prepend" }, [
      _c(
        "span",
        {
          staticClass: "input-group-text",
          attrs: { id: "inputGroup-sizing-default" }
        },
        [_vm._v("指定月份")]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "mb-5",
        staticStyle: { height: "350px", display: "none" }
      },
      [_c("canvas", { attrs: { id: "myChart" } })]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-header" }, [
      _c("h4", { staticClass: "my-0 font-weight-normal" }, [_vm._v("跳出率")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-header" }, [
      _c("h4", { staticClass: "my-0 font-weight-normal" }, [
        _vm._v("平均進站瀏覽頁數")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-header" }, [
      _c("h4", { staticClass: "my-0 font-weight-normal" }, [
        _vm._v("平均進站停留時間")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-lg-1 col-sm-1 col-3 text-right" }, [
      _c(
        "button",
        { staticClass: "btn btn-secondary", attrs: { id: "button" } },
        [_vm._v("展開")]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }